/* eslint-disable react/prop-types */
import * as React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import PageContent from "../components/common/PageContent"
import SongContent from "../components/common/SongContent"

const Song = ({ data: { prismicSong } }) => {
  const { data } = prismicSong
  return (
    <Layout>
      <PageContent>
        <SongContent data={data} />
      </PageContent>
    </Layout>
  )
}

export default Song

export const pageQuery = graphql`
  query SongBySlug($uid: String!) {
    prismicSong(uid: { eq: $uid }) {
      uid
      first_publication_date(formatString: "DD/MM/YYYY")
      last_publication_date(formatString: "DD/MM/YYYY")
      data {
        name {
          text
        }
        lyrics {
          section {
            html
          }
          section_name {
            text
          }
        }
        writer {
          text
        }
        year {
          text
        }
        copyright {
          text
        }
        spotify_link {
          url
        }
      }
    }
  }
`
