import styled from "styled-components"

const SongContent = styled.div`
  padding-top: ${({ theme }) => theme.space[5]};

  header {
    padding-bottom: ${({ theme }) => theme.space[4]};
    border-bottom: 2px solid ${({ theme }) => theme.color.darkBrown};
    margin-bottom: ${({ theme }) => theme.space[5]};
    display: inline-block;

    h1 {
      font-size: ${({ theme }) => theme.fontSize[6]};
      line-height: ${({ theme }) => theme.space[5]};

      @media ${({ theme }) => theme.device.tablet} {
        font-size: ${({ theme }) => theme.fontSize[8]};
        line-height: ${({ theme }) => theme.space[6]};
      }

      @media ${({ theme }) => theme.device.laptop} {
        font-size: ${({ theme }) => theme.fontSize[9]};
        line-height: ${({ theme }) => theme.space[7]};
      }
    }

    h1,
    p {
      margin: 0;
    }

    p {
      text-transform: uppercase;
    }
  }
`

const SongLyrics = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[8]};

  h4 {
    font-family: "ApfelGrotezk-Regular", "Helvetica", Arial, sans-serif;
    font-size: ${({ theme }) => theme.fontSize[1]};
    color: ${({ theme }) => theme.color.lightBrown};
  }
`

export default {
  SongContent,
  SongLyrics,
}
