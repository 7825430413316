import React from "react"
import Styles from "./Styles"
import Link from "../Link"
import ButtonLink from "../ButtonLink"

const SongContent = ({ data }) => {
  return (
    <Styles.SongContent>
      <header>
        <h1>{data.name.text}</h1>
        <p>
          {data.writer.text} - {data.year.text}
        </p>
        <p>
          <small>
            <Link to="/#lyrics">&larr; Back home</Link>
          </small>
        </p>
      </header>
      <Styles.SongLyrics>
        {data.lyrics &&
          data.lyrics.map(({ section, section_name }) => (
            <div>
              <h4>{section_name.text}</h4>
              {section && section.html && (
                <div dangerouslySetInnerHTML={{ __html: section.html }} />
              )}
            </div>
          ))}
      </Styles.SongLyrics>
      {data.spotify_link && data.spotify_link.url && (
        <ButtonLink to={data.spotify_link.url} label="Listen on Spotify" />
      )}
      <footer>
        <p>
          &copy; {data.copyright.text} {data.year.text}
        </p>
      </footer>
    </Styles.SongContent>
  )
}

export default SongContent
